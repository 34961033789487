.logo a img {
  width: 70%;
  padding-left: 30%;
}

.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links li {
  padding: 20px;
}

.nav-links a {
  text-decoration: none;
  color: #000;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: auto;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  box-sizing: border-box;
}

.menu-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

.helpful-materials-button a {
  padding: 8px 16px;
  background-color: rgba(172, 1, 45, 1);
  color: white;
  border-radius: 4px;
  text-decoration: none;
}
.helpful-materials-button a:hover {
  background-color: rgba(172, 1, 45, 1);
} 

/* 430px以下の画面サイズでのスタイル */
@media (max-width: 430px) {
  .nav-links {
    display: flex;
    flex-direction: column; /* 縦方向に並べる */
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .logo a img {
    padding-left: 10%;
  }

  .nav-links li {
    padding: 10px;
    border-bottom: 1px solid #ddd; /* 各リンクの下にバーを追加 */
  }

  .nav-links a {
    text-decoration: none;
    color: #000; /* リンクの色 */
  }

  .nav-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* 半透明の背景 */
  }

  /* モーダルのスタイル */
  .nav-modal {
    position: fixed;
    top: 10%; /* 上部に10%のパディングを追加 */
    right: 0;
    bottom: 0;
    width: 300px;
    height: 50%;
    background: white;
    padding: 20px;
    box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.5);
    overflow-y: auto;
  }

  /* モーダルがアクティブなときのスタイル */

  .menu-icon {
    display: block;
  }


}

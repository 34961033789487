.service-container {
  display: flex;
  align-items: center; /* アイテムを垂直方向に中央揃えにする */
  justify-content: space-between; /* コンテンツを両端に分散 */
}

.text-content {
  flex-basis: 50%; /* テキストコンテンツの幅を50%に設定 */
  padding-left: 15%;
}

.service-image {
  flex-basis: 65%; /* 画像の幅を50%に設定 */
  display: flex;
  justify-content: center; /* 画像を水平方向に中央に配置 */
  align-items: center; /* 画像を垂直方向に中央揃え */
}

.service-image img {
  max-width: 100%; /* 画像がコンテナからはみ出ないようにする */
  height: auto; /* アスペクト比を維持する */
}

.body-content {
  flex: 1;
  padding: 20px;
  padding-top: 60px;
}

.service-detail {
  margin-bottom: 20px;
  padding-top: 20px;
  padding-left: 10%;
  padding-bottom: 50px;
}

.service-intro {
  font-size: 30px;
  margin-bottom: 20px;
  padding-top: 50px;
  padding-left: 10%;
}

.service-detail,
.service-intro {
  padding-left: 10%;
}

.service-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.image-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.scaled-image {
  width: 20%;
}

.text-next-to-image {
  text-align: center;
  font-size: 60px;
  font-weight: bold;
  margin-left: 20px;
}

.text-change {
  color: rgba(172, 1, 45, 1);
}

.center-text {
  font-size: 64px;
  font-weight: bold;
  text-align: center;
  padding: 100px 0;
  margin: 20px 0;
}

.center-textrecruit {
  font-size: 64px;
  font-weight: bold;
  text-align: center;
  padding: 100px 0;
  margin: 20px 0;
}

.more-button1 {
  display: block; /* ブロック要素として表示 */
  width: 120px; /* コンテナの30%の幅 */
  margin: 10px auto; /* 上下のマージンを指定し、左右のマージンを自動にする */
  padding: 10px 20px; /* パディングを適用 */
  background-color: rgba(172, 1, 45, 1); /* 背景色を設定 */
  color: #fff; /* テキストの色を白に */
  text-decoration: none; /* テキストの下線をなくす */
  text-align: center; /* テキストを中央揃えに */
  border-radius: 15px;
}

.recruit-text {
  font-size: 12px;

  text-align: center;
  padding-top: 30px;
  padding-bottom: 15%;
}

.more-button2 {
  box-sizing: border-box; /* パディングとボーダーを幅に含める */
  width: 120px; /* パディングとボーダーを含めた全体の幅を120pxに設定 */
  padding: 10px 20px; /* 上下のパディングを10px、左右のパディングを20pxに設定 */
  background-color: rgb(60, 60, 60); /* 背景色を設定 */
  color: #fff; /* テキストの色を白に */
  text-decoration: none; /* テキストの下線をなくす */
  text-align: center; /* テキストを中央揃えに */
  margin: 0 auto; /* 上下のマージンを0にし、左右のマージンを自動に設定して中央揃えにする */
  border-radius: 15px;
}

.recruit-title {
  font-size: 40px; /* 文字サイズを40pxに設定 */
  margin: 0; /* デフォルトのマージンをリセット */
  padding: 20px 0; /* 上下にパディングを追加 */
  text-align: center;
}

/* 採用メインテキストのスタイル */
.recruit-main {
  width: 70%;
  font-size: 20px; /* 文字サイズを24pxに設定 */
  margin: 0 auto; /* 上下のマージンを0にし、左右のマージンを自動に設定 */
  padding: 50px 0; /* 上下にパディングを追加 */
  text-align: center; /* テキストを中央揃えに */
}

.box-container {
  display: flex;
  justify-content: center; /* ボックスを中央揃えに */
  flex-wrap: wrap; /* 必要に応じて折り返しを行う */
  gap: 5%; /* ボックス間に5%の間隔を作る */
}

.box {
  width: 40%; /* ボックスの幅をコンテナの40%に設定 */
  text-align: center; /* テキストを中央揃えに */
  margin-bottom: 20px; /* ボックス間の縦の余白を設定 */
  padding-top: 30px;
}

.box img {
  max-width: 70%; /* 画像の最大幅を画面の30%に設定 */
  height: auto; /* 画像の高さを自動調整してアスペクト比を維持 */
  display: block; /* 画像をブロック要素として表示 */
  margin: 0 auto; /* 画像を中央に配置 */
  padding-top: 30px;
  padding-bottom: 30px;
}

.box h2 {
  margin-bottom: 0.5em; /* タイトルの下に余白を設ける */
}

.box p {
  width: 70%; /* p要素の幅を70%に設定 */
  margin: 0 auto; /* 上下のマージンを0にし、左右のマージンを自動に設定して中央揃えに */
  margin-top: 0.5em; /* 詳細の上に余白を設ける */
  padding-bottom: 30%;
}

* {
  box-sizing: border-box;
}

.break-on-small {
  display: none;
}

/* HomePage.css */
.news-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.news-item h3 {
  margin-right: 15px; /* タイトルと説明の間隔 */
}

.news-item a {
  color: rgba(172, 1, 45, 1);
  text-decoration: none;
}

.news-item a:hover {
  text-decoration: underline;
}

.show-more-news {
  text-align: center;
  margin-top: 20px;
}

/* モーダルのバックドロップ */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* モーダルのコンテンツ */
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

.news-container-scroll {
  border: 2px solid #ccc; /* 枠線を追加 */
  border-radius: 5px; /* 角を丸く */
  max-height: 400px; /* 最大高さを設定 */
  overflow-y: auto; /* 縦方向にスクロール可能に */
  padding: 10px; /* 内側の余白 */
}

.news-section {
  padding: 20px;
}

.news-container {
  border: 1px solid #ddd;
  border-radius: 8px;
  max-height: 300px;
  overflow-y: auto;
  padding: 15px;
  width: 70%; /* コンテナの幅を80%に設定 */
  margin: 0 auto; /* 水平方向のマージンを自動に設定して中央揃えに */
}
.title-description-container {
  display: flex;
  align-items: center; /* タイトルと説明を垂直方向に中央揃えにする */
  justify-content: space-between; /* タイトルと説明をコンテナの両端に配置 */
  margin-bottom: 10px; /* 詳細リンクへの余白を設定 */
}

.news-item {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 0px;
  border-bottom: 1px solid #eee;
}

.news-item:last-child {
  border-bottom: none; /* 最後の項目には区切り線を表示しない */
}

.news-detail {
  margin-top: 5px; /* 詳細リンクの上部余白 */
}

.news-detail a {
  color: #0066cc; /* リンクの色 */
  text-decoration: none; /* 下線を消す */
}

.news-detail a:hover {
  text-decoration: underline; /* ホバー時に下線を表示 */
}

.title-description-container p {
  flex: 1;
  margin: 5px; /* タイトルと説明の間隔を設定 */
  white-space: nowrap; /* 折り返しを防ぐ */
  overflow: hidden; /* はみ出たテキストを非表示に */
  text-overflow: ellipsis; /* はみ出たテキストを省略記号で表現 */
}

@media (max-width: 430px) {
  .service-container {
    flex-direction: column; /* コンテンツを縦に並べる */
    padding-left: 10%;
  }
  .scaled-image {
    width: 40%;
    padding-right: 30px;
  }
  .break-text {
    display: block; /* スマートフォン表示時に改行を挿入 */
  }

  .center-textrecruit {
    padding-top: 50px;
    padding-bottom: 10px;
  }

  .body-content {
    padding-top: 20px;
  }
  .service-image {
    display: none; /* 画面幅が430px以下のときに画像を非表示にする */
    width: 80%;
  }

  .image-text {
    padding-top: 20%;
  }

  .text-content,
  .service-image {
    flex-basis: 100%; /* コンテンツを画面幅いっぱいに表示 */
    padding-left: 0; /* 左のパディングを削除 */
    padding-top: 10px;
  }

  .service-image img {
    max-width: 80%; /* 画像の最大幅を調整 */
    margin: 0 auto; /* 画像を中央に配置 */
  }

  .text-next-to-image {
    font-size: 18px; /* フォントサイズを小さくする */
    margin-left: 0; /* 余白を調整 */
    text-align: left; /* テキストを左揃えに */
  }

  .center-text,
  .center-textrecruit {
    font-size: 32px; /* 中央テキストのフォントサイズを調整 */
  }

  .center-text {
    padding: 10px;
  }

  .service-intro {
    font-size: 18px;
    padding: 10px 50px;
    font-weight: bold;
  }
  .service-detail {
    font-size: 10px;
    padding: 10px;
    width: 80%;
    margin: 0 auto;
  }

  .recruit-title {
    font-size: 18px;
  }
  .recruit-main {
    font-size: 10px;
    width: 90%;
    padding: 10px;
  }
  .more-button2 {
    font-size: 10px;
  }

  .more-button1 {
    font-size: 10px;
    width: 120px;
  }

  .box {
    width: 100%; /* ボックスの幅を100%に設定 */
  }

  .box img {
    max-width: 100%; /* 画像の最大幅を100%に設定 */
  }

  .box p {
    width: 90%; /* p要素の幅を90%に設定 */
  }
  .break-on-small {
    display: block;

    /* その他の要素に対する追加のスタイル調整 */
  }
  .youtubeTitle {
    font-size: 20px;
  }
  .media-description {
    font-size: 10px;
  }
  .box {
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .box p {
    padding-bottom: 10px;
  }
}

@media (max-width: 10240px) {
  .text-next-to-image {
    font-size: 48px;
  }
  .service-intro {
    font-size: 24px;
    font-weight: bold;
    padding-left: 0px;
  }
  .service-detail {
    margin: 0px;
    padding-left: 0px;
  }
  .recruit-main {
    font-size: 18px;
  }
}

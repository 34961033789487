/* NewsDetailPage.css */
.news-detail-container {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center; /* 中央揃えに */
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px; /* フッターとの間に100pxの余白を持たせる */
}

.news-image {
  width: 200px; /* 画像のサイズは必要に応じて調整 */
  height: 200px;
  margin-bottom: 20px; /* 画像の下の余白 */
}

.news-form {
  margin-top: 20px;
}

.news-form div {
  margin-bottom: 10px;
}

.news-form button {
  margin-top: 10px;
}

.back-to-top-btn {
  padding: 10px 20px;
  margin-top: 20px; /* コンテンツとボタンの間のマージン */
  background-color: rgba(172, 1, 45, 1);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block; /* ブロックレベルで表示 */
  margin-bottom: 20px; /* フッターとの間の余白 */
  /* 中央に配置するためのスタイルを削除 */
}

.back-to-top-btn:hover {
  background-color: rgb(197, 31, 73);
}

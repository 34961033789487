/* ContentDetailPage.cssに追加 */
.detail-container {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  margin-bottom: 100px;
  width: 80%; /* 全体の横幅を80%に設定 */
  margin-left: auto;
  margin-right: auto;
}

.detail-content {
  width: 60%; /* コンテンツ情報の横幅を全体の60%に設定 */
}

.detail-image {
  width: 100%; /* コンテンツ情報内の画像の幅を100%に設定 */
  height: auto;
  margin-bottom: 20px; /* 画像とタイトルの間に余白を設定 */
}

.form-container {
  width: 38%; /* フォームの横幅を全体の38%に設定 */
  height: 100%;
  padding: 20px; /* 上下左右のパディングを設定 */
  padding-bottom: 10px; /* 下部のパディングを減らす */
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 0; /* コンテナの下部のマージンを削除 */
}

.form-group {
  margin-bottom: 10px; /* 入力フィールド間の余白を調整 */
}

.form-group label {
  display: block;
  margin-bottom: 5px; /* ラベルと入力フィールド間の間隔 */
}

.form-group input,
.form-group textarea {
  /* テキストエリアを追加してスタイルを適用 */
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  /* 入力フィールドの高さは内容に応じて自動調整 */
}

/* フォーム送信ボタンのスタイル */
button[type='submit'] {
  width: 100%;
  padding: 10px;
  background-color: #0555aa;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px; /* ボタンの上の余白 */
  margin-bottom: 20px; /* ボタンの下の余白を削除 */
}

button[type='submit']:hover {
  background-color: #2872c1; /* ホバー時の背景色を濃くする */
}

/* ポップアップの背景スタイル */
.popup {
  position: fixed; /* 画面上に固定 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明の黒背景 */
  display: flex;
  justify-content: center;
  align-items: center; /* 中央寄せ */
  z-index: 1000; /* 他の要素より前面に表示 */
}

/* モーダル内容のスタイル */
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px; /* 最大幅を設定 */
  width: 90%; /* モーダルの幅は画面幅の90%まで */
}

.contact-form-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 10%;
}

.contact-form-container .logo img {
  max-width: 100px;
  display: block;
  margin: 0 auto 20px;
}

.contact-form-container h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.contact-form-container .form-group {
  margin-bottom: 15px;
}

.contact-form-container .form-group label {
  display: block;
  margin-bottom: 5px;
}

.contact-form-container .form-group input[type='text'],
.contact-form-container .form-group input[type='email'],
.contact-form-container .form-group input[type='tel'],
.contact-form-container .form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.contact-form-container .form-group textarea {
  height: 100px;
}

.contact-form-container button {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  background-color: rgba(172, 1, 45, 1);
  color: white;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.contact-form-container button:hover {
  background-color: rgba(172, 1, 45, 1);
}

.wpDlbutton {
  background-color: aqua;
}

.popup {
  position: fixed; /* 画面に固定 */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明の黒 */
  display: flex;
  align-items: center; /* 中央揃え */
  justify-content: center; /* 中央揃え */
}

/* モーダルのコンテンツスタイル */
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  display: flex; /* Flexコンテナとして設定 */
  flex-direction: column; /* 子要素を縦方向に並べる */
  align-items: center; /* 子要素を中央揃え */
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background-color: #000; /* フッターの背景色 */
  color: #fff; /* フッターのテキスト色 */
}

.footer-content {
  width: 100%;
  display: flex;
  justify-content: flex-start; /* 左詰めに変更 */
  flex-wrap: wrap; /* 必要に応じて折り返し */
  padding-top: 10%;
}

.footer-box {
  margin-right: 30px; /* ボックス間の余白を30pxに設定 */
  margin-bottom: 20px; /* ボックス間の余白 */
  padding-bottom: 10%;
  padding-left: 5%;
}

.footer-box h3 {
  margin-bottom: 10px;
}

.footer-links a {
  display: block; /* リンクをブロック要素として表示 */
  color: #fff; /* リンクのテキスト色 */
  text-decoration: none;
  margin-bottom: 5px; /* リンク間のマージン */
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-logo {
  width: 100%; /* ロゴセクションの幅を100%に設定 */
  margin-top: 20px; /* ロゴとテキストボックス間の余白 */
  padding-bottom: 10%;
  padding-left: 5%;
}

.footer-logo img {
  max-width: 100px; /* ロゴの最大幅を設定 */
  height: auto; /* アスペクト比を維持する */
}

.footer-logo p {
  margin-top: 10px; /* ロゴと著作権テキスト間の余白 */
  font-size: 0.8em; /* 著作権テキストのサイズを小さくする */
}

@media (max-width: 430px) {
  .footer-box {
    padding: 10px;
  }
  .footer-content {
    padding: 0px;
  }
  .footer-logo {
    padding: 0px;
  }
}

.content-header {
  text-align: center;
  margin-top: 100px; /* トップに100pxの間を作る */
  margin-bottom: 50px; /* タイトルとコンテンツの間に間隔を設ける */
}

.content-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* ボックス間の間隔 */
  justify-content: center;
  width: 80%; /* 画面の80%の範囲に設定 */
  margin: 0 auto 100px; /* 中央揃え、下部に100pxのマージンを追加 */
}

.content-box {
  width: calc(30% - 20px); /* 3つのボックスが横に並ぶようにする */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* コンテンツに縁取り（シャドウ）を設定 */
  overflow: hidden;
  background: #fff; /* 背景色 */
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* ホバー時のシャドウとサイズ変更のアニメーション */
  display: flex;
  flex-direction: column; /* ボックス内も縦並びに */
}

.content-box:hover {
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2); /* ホバー時にシャドウを強調 */
  transform: scale(1.05); /* ホバー時にサイズを5%大きくする */
}

.content-link {
  display: flex;
  flex-direction: column; /* 縦並びに変更 */
  text-decoration: none;
  color: black; /* リンクのテ���スト色 */
  height: 100%; /* 縦幅をボックス全体に合わせる */
}

.content-image {
  width: 100%; /* 画像の幅をボックスに合わせる */
  height: auto; /* 高さを自動調整 */
  object-fit: contain; /* 画像全体が表示されるように調整 */
}

.content-text {
  height: 100px; /* テキスト部分の高さを調整 */
  padding: 10px;
  overflow: hidden; /* テキストが溢れないようにする */
}

.content-text h2 {
  font-size: 1.2em; /* タイトルのフォントサイズ */
  margin: 0 0 10px 0; /* タイトルのマージン */
}

.content-text p {
  font-size: 1em; /* 説明文のフォントサイズ */
  margin: 0; /* 説明文のマージン */
}

@media (max-width: 768px) {
  .content-box {
    width: 100%; /* モバイルでは1列に */
  }

  .content-link {
    flex-direction: column; /* モバイルでは縦並びに */
  }

  .content-list {
    width: 100%; /* モバイルでは画面の100%の範囲に設定 */
  }
}

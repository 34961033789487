.mvv {
  display: flex;
  flex-direction: column;
  align-items: center; /* 中央揃えに戻す */
  margin: 50px 0;
  padding-top: 10%;
}

.mvv .title,
.mvv .highlight {
  display: inline-block; /* インラインブロック要素にする */
  text-align: left; /* テキストを左揃えにする */
  min-width: 150px; /* 最小幅を設定してタイトルの幅を揃える */
  vertical-align: middle;
  font-weight: bold;
  padding-bottom: 30px;
}

.mvv .title {
  margin-right: 10px; /* タイトルとハイライトの間にスペースを作る */
  font-size: 36px;
}

.mvv .highlight {
  padding-left: 50px; /* ハイライトテキストの開始位置を揃えるためのパディング */
  font-size: 18px;
}

.red-text {
  color: rgba(172, 1, 45, 1); /* 赤色のテキスト */
}

/* その他のスタイルはそのまま維持 */
.company-info {
  max-width: 800px;
  margin: 0 auto;
  padding: 10%;
  font-family: Arial, sans-serif;
}

.company-info h2 {
  font-size: 40px;
  text-align: center;
  margin-bottom: 30px;
  padding-bottom: 10%;
}

.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.company-info h2 {
  font-size: 40px;
  text-align: center;
  margin-bottom: 30px;
  padding-bottom: 10%;
}

.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.info-title {
  font-weight: bold;
  width: 15%; /* 左側の要素の幅を固定する */
}

.info-content {
  text-align: left; /* 右側の要素を左詰めにする */
  flex-grow: 1; /* 右側の要素が残りのスペースを埋めるようにする */
  padding-left: 15%; /* 左側の要素から15%の間隔を開ける */
}

hr {
  border: none;
  height: 1px;
  background-color: #ccc;
  margin: 20px 0;
}

@media (max-width: 430px) {
  .mvv {
    flex-direction: column;
  }
  .mvv .title {
    font-size: 24px;
    padding: 0px;
    margin: 20px;
  }

  .title {
    font-size: 18px;
  }
  .mvv .highlight {
    padding: 0px;
    font-size: 12px;
  }
  .mvv {
    margin-bottom: 10px;
  }
  .info-title,
  .info-content {
    font-size: 8px;
  }
  .company-info h2 {
    font-size: 24px;
  }
}

.content-row {
  display: flex;
  align-items: center;
  justify-content: center; /* 中央揃えに設定 */
  padding-top: 10%;
}

.content-column {
  text-align: center; /* 中央揃え */
  margin-bottom: 20px; /* 下に余白を追加 */
}

.image-block,
.text-container {
  flex-basis: 40%; /* 画像とテキストコンテナの幅を40%に設定 */
  margin: 0 10px; /* 両側に余白を追加 */
}

.image-block {
  flex-basis: 30%; /* 画像の幅を40%に設定 */
  max-width: 30%; /* 画像の最大幅を40%に設定 */
  height: auto; /* 画像の高さを自動調整 */
}

.serviceLogo {
  max-width: 50%; /* 画像の最大幅を50%に設定 */
  height: auto; /* 画像の高さを自動調整 */
}

.text-container {
  flex-basis: 50%; /* テキストコンテナの幅を40%に設定 */
  display: flex;
  flex-direction: column; /* テキストを縦に並べる */
  justify-content: center; /* 中央揃え */
  padding-left: 5%;
  padding-right: 5%;
}

.text-block,
.text-short,
.text-long {
  margin: 10px 0; /* テキスト間の余白を設定 */
}

.text-subtitle {
  font-size: 30px;
  margin-top: 20px;
  text-align: center;
}

.service-title {
  font-size: 24px;
  color: rgba(172, 1, 45, 1);
  margin-right: 10px;
}

.text-description {
  margin-top: 15px; /* 上のテキストブロックからの余白を追加 */
  font-size: 30px;
}

.text-title {
  font-size: 24px;
  margin-bottom: 10px; /* タイトルと説明文の間に余白を追加 */
  font-weight: bold;
}

.text-description {
  font-size: 16px;
}

/* 最初と4つ目のdivの中央揃えスタイル */
.center-aligned {
  justify-content: center; /* 中央揃え */
}

/* 2つ目のdivの画像左、テキスト右スタイル */
.image-left {
  justify-content: center;
}

/* 3つ目のdivのテキスト左、画像右スタイル */
/* 3つ目のdivのテキスト左、画像右スタイル */
.text-right {
  justify-content: center; /* 中央揃え */
  flex-direction: row; /* デフォルトの順序（テキストを左、画像を右に） */
}

/* ボタンスタイルはそのまま */

.spacer-box {
  height: 50vh; /* ビューポートの高さの10% */
}

.more-button1 {
  width: 200px;
}

@media (max-width: 430px) {
  .content-row {
    flex-direction: column; /* アイテムを縦に並べる */
  }
  .image-left {
    padding-top: 10px;
  }

  .image-block {
    flex-basis: 100%; /* 画像の基本サイズを100%に設定 */
    max-width: 100%;
    width: 80%; /* 画像の最大幅を100%に設定 */
    height: auto; /* 画像の高さを自動調整し、アスペクト比を維持 */
    object-fit: contain; /* 画像がコンテナに収まるように調整し、アスペクト比を維持 */
  }

  .text-container {
    flex-basis: 100%; /* 画像とテキストコンテナの幅を100%に設定 */
    max-width: 100%; /* 最大幅も100%に設定 */
    margin: 0 0 20px 0; /* 下に余白を追加 */
    order: 1;
  }

  .service-title {
    font-size: 0px;
    font-weight: bold;
    padding-top: 30px;
  }

  .serviceLogo {
    height: 30px;
  }

  .text-subtitle {
    font-size: 24px;
    width: 80%;
    margin-left: 30px;
    text-align: left;
    font-weight: bold;
    padding-top: 30px;
    border-bottom: 3px solid #000;
  }

  .text-description {
    font-size: 14px; /* テキストのサイズを小さく調整 */
  }

  .text-title {
    font-size: 18px;
    width: 90%;
    margin-left: 30px;
  }
  .text-description {
    font-size: 9px;
    width: 90%;
    margin-left: 30px;
  }
  .spacer-box {
    height: 30px;
  }

  /* 他のスタイル調整が必要な場合はここに追加 */
}
